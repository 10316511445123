<div id="two-factor-authentication">
  <div *eCaseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" class="center-me">
    <mat-card fxLayout="column" style="width: 700px">
      <div *ngIf="showLanguageSwitcher">
        <mat-form-field [style]="isMobile ? '' : 'width:30%;float:right;'" appearance="outline">
          <mat-select (ngModelChange)="setLanguage($event)" [(ngModel)]="selectedLanguage">
            <mat-option *ngFor="let language of languages" [value]="language">{{language.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="logo" fxLayout="row" fxLayoutAlign="center center">
        <img *ngIf="isDropShadowEnabled"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo"
             style="filter: drop-shadow(0px 0px 5px #000);width:50%">
        <img *ngIf="!isDropShadowEnabled"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo" style="width:50%">
      </div>

      <form *ngIf="!isCodeSent" [formGroup]="twoFactorAuthenticationForm" name="forgotPasswordForm"
            style="margin-top: 20px;">

        <span>{{'ecase.common.2fa.description' | translate}}</span>
        <br>
        <br>
        <mat-radio-group [formControlName]="'authenticationMode'" fxLayout="row" [fxLayoutAlign]="mfaModes.length === 3 ? 'space-between center' : 'space-evenly'"
                         [fxLayoutGap]="mfaModes.length === 2 ? '3%' : '0'">
          <mat-radio-button *ngIf="mfaModes.includes('username')"
                            value="0">{{'ecase.common.email' | translate}}</mat-radio-button>
          <mat-radio-button *ngIf="mfaModes.includes('altEmail')" [disabled]="!isAlternativeEmailAvailable"
                            value="1">{{'ecase.common.alternativeemail' | translate}}</mat-radio-button>
          <mat-radio-button *ngIf="mfaModes.includes('sms')" [disabled]="!isPhoneNumberAvailable"
                            value="2">{{'ecase.common.mobiledevicenumber' | translate}}</mat-radio-button>
        </mat-radio-group>

        <br>

        <ng-container *ngIf="mfaModes.includes('altEmail')">
          <mat-label><span style="color: red">* </span>{{ 'ecase.common.alternativeemail' | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input [attr.aria-label]="'ecase.common.alternativeemail' | translate" formControlName="alternativeEmail"
                   matInput>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="mfaModes.includes('sms')">
          <mat-label><span style="color: red">* </span>{{ 'ecase.common.mobiledevicenumber' | translate }}</mat-label>
          <div [style.width.%]="isMobile ? 100 : 50">
            <mat-form-field appearance="outline">
              <input [attr.aria-label]="'ecase.common.mobiledevicenumber' | translate" formControlName="originalFormat"
                     matInput>
            </mat-form-field>
          </div>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="center center">
          <button (click)="sendSecurityCode(twoFactorAuthenticationForm.controls['authenticationMode'].value)" [disabled]="twoFactorAuthenticationForm.invalid"
                  class="submit-button"
                  color="accent" mat-raised-button>
            {{ 'ecase.common.sendsecuritycode' | translate }}
          </button>
        </div>
      </form>

      <form *ngIf="isCodeSent" [formGroup]="verifyCodeForm" name="verifyCodeForm" style="margin-top: 20px;">
        <span>{{'ecase.common.2fa.description2' | translate}}</span>
        <br>
        <br>

        <mat-label><span style="color: red">* </span>{{ 'ecase.common.pleaseentersecuritycode' | translate }}
        </mat-label>
        <br>
        <mat-form-field [style]="isMobile ? '' : 'width: 20%;'" appearance="outline">
          <input [attr.aria-label]="'ecase.common.pleaseentersecuritycode' | translate" formControlName="securityCode"
                 matInput
                 maxlength="25">
          <mat-error *ngIf="verifyCodeForm.get('securityCode').errors?.required"
                     syle="width:200px"> {{'ecase.common.thisfieldismandatory' | translate}}
          </mat-error>
        </mat-form-field>

        <br>
        <mat-label><span style="color: red">* </span>{{ 'ecase.common.isdevicetrusted' | translate }}</mat-label>
        <mat-radio-group [formControlName]="'isDeviceTrusted'" fxLayout="row">
          <mat-radio-button *ngFor="let term of yesNoList" [value]="term.id"
                            style="margin-left: 15px">{{term.label[selectedLanguage.id]}}</mat-radio-button>
        </mat-radio-group>

        <div fxLayout="row" fxLayoutAlign="center center">
          <button (click)="verifyCodeAndSignIn()" [disabled]="verifyCodeForm.invalid"
                  class="submit-button"
                  color="accent" mat-raised-button>
            {{ 'login' | translate }}
          </button>

          <button (click)="sendSecurityCode(twoFactorAuthenticationForm.controls['authenticationMode'].value)"
                  class="submit-buttosn" color="accent"
                  mat-raised-button style="margin-left: 15px">
            {{ 'ecase.common.resendsecuritycode' | translate }}
          </button>
        </div>

      </form>
      <br>
      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a (click)="exitAndLogOut()" class="link"> {{ 'GOBACK' | translate }}</a>
      </div>
    </mat-card>
  </div>
</div>




