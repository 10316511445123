import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root'
})
export class AdminCrmService {

  dataSourceCrmPerson = [];
  selectedPersonId;
  selectedPerson;
  emailAddressOfLinkedContact: any = null;
  continuationFromOrganizationScreen = null;

  // These variables are for perserving search data
  preserveDataSource: any = false;
  preserveFormData: any = false;
  preserveFilterObject: any = false;

  constructor(private eCaseHttpService: EcaseHttpService) {
  }


  /*  CRM PERSON SERVICES */

  public getComplementaryInformationFormDataForFieldsForCrmPerson(formId: number, userId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getComplementaryInformationFormDataForFieldsForCrmPerson', {
      'formId': formId,
      'caseJsonDataRepositoryId': -1,
      'userId': userId,
      'isFormForConsent': true
    });
  }

  getAllIdentitiesForCrmPerson(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllIdentitiesForCrmPerson');
  }

  searchCrmPersonOld(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/searchCrmPerson', obj);
  }

  searchCrmPerson(obj): Observable<any> {
    return this.eCaseHttpService.post('/v2_api/crm-person/search', obj);
  }

  countAllPersons(): Observable<any> {
    return this.eCaseHttpService.get('/api/countCrmPerson');
  }

  verifyPhoneNumberForCrmPerson(phoneNumber: string): Observable<any> {
    return this.eCaseHttpService.post('/api/verifyPhoneNumberForCrmPerson', {
      'numberToVerify' : phoneNumber
    });
  }

  getRoleListForCrmPerson(): Observable<any> {
    return this.eCaseHttpService.get('/api/getRoleListForCrmPerson');
  }

  getCrmOrgListForCrmPersonSearch(): Observable<any> {
    return this.eCaseHttpService.get('/api/getCrmOrganizationListForCrmPersonSearch');
  }

  archiveUnarchiveCrmPerson(crmPersonId, isArchived): Observable<any> {
    return this.eCaseHttpService.post('/api/archiveUnarchiveCrmPerson', {
      "crmPersonId": crmPersonId,
      "isArchived": isArchived
    });
  }

  validateEmailAddress(emailAddress, isOrg = false): Observable<any> {
    return this.eCaseHttpService.post('/api/validateEmailAddress', {'emailAddress': emailAddress,'isOrg':isOrg});
  }

  getUserAndOrganizationForInvitation (emailAddress): Observable<any> {
    return this.eCaseHttpService.post('/api/getUserAndOrganizationForInvitation', {'emailAddress': emailAddress});
  }

  validateCrmPersonForOrganization(organizationId, crmPersonId): Observable<any> {
    return this.eCaseHttpService.post('/api/validateCrmPersonForOrganization', {'organizationId': organizationId, 'crmPersonId' : crmPersonId});
  }

  editCrmPersonById(id): Observable<any> {
    return this.eCaseHttpService.get('/api/editCrmPersonById/' + id);
  }

  addOrUpdateCrmPerson(obj: any): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateCrmPerson', obj);
  }

  revokeTrustedDevices(ssoUserId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/revokeTrustedDevices', {ssoUserId});
  }

  getAllAffiliationsForCrmPerson(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllAffiliationsForCrmPerson');
  }
/*METHOD FOR GET ROLS*/
  getRoleListForSsoUser(): Observable<any> {
    return this.eCaseHttpService.post('/api/getRoleListForSsoUser', {});
  }

  getCrmPersonContributionsByCrmPersonId(crmPersonId): Observable<any> {
    return this.eCaseHttpService.get('/api/getCrmPersonContributionsByCrmPersonId/' + crmPersonId);
  }

  getCrmPersonContributionsByApplicationId(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getCrmPersonContributionsByApplicationId/' + applicationId);
  }

  getCrmKeyWord(crmPartyId) {
    return this.eCaseHttpService.get(`/api/getCrmKeywords/${crmPartyId}`);
  }

  insertCrmKeyword(objectToInsert) {
    return this.eCaseHttpService.post(`/api/insertCrmKeyword`, objectToInsert);
  }

  createNewTermKeyword(objectToInsert) {
    return this.eCaseHttpService.post(`/api/createNewTermKeyword`, objectToInsert);
  }
  editKeywordComment(objectToInsert) {
    return this.eCaseHttpService.post(`/api/editKeywordComment`, objectToInsert);
  }

  deleteCrmKeyword(objectToInsert) {
    return this.eCaseHttpService.post(`/api/deleteCrmKeyword`, objectToInsert);
  }


  /*  METHODS USED ALL OVER CRM MODULE*/
  sortLovArrayAlphabetically(sortingArray, selectedLang): any {
    sortingArray = (sortingArray ? sortingArray : []).sort((a, b) => {
      let result = 0;
      try{
        (a.value[selectedLang] === null || a.value[selectedLang] === undefined) ? a.value[selectedLang] = "" : a.value[selectedLang];
        (b.value[selectedLang] === null || b.value[selectedLang] === undefined) ? b.value[selectedLang] = "" : b.value[selectedLang];
        result = (a.value[selectedLang]).toLowerCase().localeCompare((b.value[selectedLang]).toLowerCase());
      }catch(e){
        console.error(e);
      }
      return result;
    });
    return sortingArray;
  }

  sortTermArrayAlphabetically(sortingArray, selectedLang) {
    sortingArray = sortingArray.sort((a, b) => {
      (a.label[selectedLang] === null || a.label[selectedLang] === undefined) ? a.label[selectedLang] = "" : a.label[selectedLang];
      (b.label[selectedLang] === null || b.label[selectedLang] === undefined) ? b.label[selectedLang] = "" : b.label[selectedLang];
      return (a.label[selectedLang]).toLowerCase().localeCompare((b.label[selectedLang]).toLowerCase());

    });
    return sortingArray;
  }

  isPrincipalFieldAvailable(dataSet: any[], action: string): boolean {
    console.log(dataSet);
    let isPrincipalOptionAllowed;
    if (action === 'add') {
      if (dataSet.length === 0) {
        isPrincipalOptionAllowed = true;
      } else {
        // dataSet HAS values
        isPrincipalOptionAllowed = !(dataSet.filter(item => item.isPrincipal).map(item => item.isPrincipal)[0]);
        console.log("dataSet HAS values ", isPrincipalOptionAllowed);
      }
    } else {
      // EDIT LOGIC
      isPrincipalOptionAllowed = true;
    }

    return isPrincipalOptionAllowed;
  }

}
